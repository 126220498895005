<template>
  <!-- 水表抄表卡 -->
  <div id="app">
    <div class="container">
      <!-- 头部 -->
      <el-row :gutter="10">
        <el-col :span="4">
          <el-date-picker
            style="width: 100%"
            v-model="pickerValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="datePicker"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-cascader
            style="width: 100%"
            placeholder="请选择单位"
            :show-all-levels="false"
            clearable
            ref="cascader"
            :options="allCompanyList"
            v-model="companyName"
            @change="checkedCompany"
            :props="defaultProps"
          >
          </el-cascader>
        </el-col>
        <el-col :span="1.5">
          <el-button type="primary" @click="getDeviceData">查 询</el-button>
          <!-- <el-button type="primary">导 出</el-button> -->
        </el-col>
        <!-- 导出excel表格 -->
        <el-col :span="2">
          <download-excel
            class="export-excel-wrapper"
            :data="tableData"
            :fields="meterReadingFields"
            name="抄表数据.xls"
          >
            <el-button type="primary">导出EXCEL</el-button>
          </download-excel>
        </el-col>
          <!-- 数据来源注明 -->
          <el-col :span="6" :push="10">
            <span style="color: aquamarine;;line-height:40px;">注：本表用水量数据来源于水表读数相减</span>
          </el-col>
      </el-row>

      <div class="content">
        <el-table
          border
          height="100%"
          :data="tableData"
          style="border-radius: 5px; width: 100%"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.4)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column type="index" label="序号" width="60" align="center">
          </el-table-column>
          <el-table-column prop="coding" label="编号" align="center">
          </el-table-column>
          <el-table-column prop="deviceId" label="IMEI" align="center">
          </el-table-column>
          <el-table-column prop="specs" label="规格" width="250" align="center">
          </el-table-column>
          <el-table-column prop="ciId" label="单位" width="250" align="center">
          </el-table-column>
          <el-table-column
            prop="place"
            label="安装位置"
            width="220"
            align="center"
          >
          </el-table-column>
          
          <el-table-column label="开始读数" align="center" width="200">
            <template slot-scope="scope">
              {{
                scope.row.beforeData
                  ? scope.row.beforeData.toFixed(2) + "m³"
                  : "0"
              }}
            </template>
          </el-table-column>
		  <el-table-column label="结束读数" width="200" align="center">
		    <template slot-scope="scope">
		      <div>{{ scope.row.nowData.toFixed(2) }} m³</div>
		    </template>
		  </el-table-column>
          <el-table-column
            prop="periodDifference"
            label="期间水量"
            width="200"
            align="center"
          >
            <!-- <template slot-scope="scope">
              {{ scope.row.data ? scope.row.data + 'm³' : '无' }}
            </template> -->
          </el-table-column>
        </el-table>

        <!-- <el-pagination style="text-align: center" background :current-page.sync="current" :page-size="limit"
          :total="total" layout="total,prev,pager,next,jumper" @current-change="pagination"></el-pagination> -->
      </div>
      <div class="summation">合计：（m³）<span>{{ summation.toFixed(2) }}</span></div>
    </div>
  </div>
</template>

<script>
import companySystem from "@/api/managementApi/CompanyInfo";
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";
import Company from "@/api/managementApi/CompanyInfo";

export default {
  components: {},
  props: {},
  data() {
    
    return {
      // 表格文件
      meterReadingFields:{
      "编号":"coding",
      "规格":"specs",
      "单位":"ciId",
      "安装位置":"place",
      "本月度数":"nowData",
      "上月读数":"beforeData",
      "期间水量":"periodDifference",  
      },
      //定义求和值
      summation: 0,

      loading: true,
      pickerValue: [],
      companyName: "",
      current: 1,
      limit: 1000,
      total: 0,
      allCompanyList: [],
      companyList: [],
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "id",
        checkStrictly: true,
      },
      ciId: "",
      tableData: [],
    };
  },
  created() {
    this.getCompany();
    // 设定默认日期
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth()
    let month1 = date.getMonth() + 1
    let day = '20'
    let time = '00:00:00'
    if (month < 10) {
      month = '0' + month
    }
    if (month1 < 10) {
      month1 = '0' + month1
    }
    let startTime = year + '-' + month + '-' + day + ' ' + time
    let endTime = year + '-' + month1 + '-' + day + ' ' + time
    this.pickerValue[0] = startTime
    this.pickerValue[1] = endTime
    console.log(this.pickerValue);


  },

  methods: {
    // 选择每行触发事件
    handleSelectionChange(value) {
      console.log(value);
      this.summation = 0;
      for (let i = 0; i < value.length; i++) {
        this.summation += Number(value[i].periodDifference);
      }
      console.log(this.summation);
    },

    // 查公司结构
    async getCompany() {
      // 查公司结构
      const company = await companySystem.getAllCompanyStructure();
      this.allCompanyList = company.data.data.list;
      console.log(company);

      const res = await Company.getCompanyInfo();
      console.log(res);
      this.companyList = res.data.data.list;

      this.getDeviceData();
    },

    async getDeviceData() {
      this.loading = true;
      let obj = {};
      let obj1 = {
        deviceTypeId: "DXwaterMeter",
        startTime: this.pickerValue[0],
        endTime: this.pickerValue[1],
        current: this.current,
        limit: this.limit,
      };
      let obj2 = {
        deviceTypeId: "DXwaterMeter",
        startTime: this.pickerValue[0],
        endTime: this.pickerValue[1],
        current: this.current,
        limit: this.limit,
        ciId: this.ciId,
      };
      this.ciId == "" ? (obj = obj1) : (obj = obj2);

      const res = await DeviceHistoryValue.getWaterMeterDataAndInfo(obj);
      console.log(res);
      if (res.data.data) {
        this.loading = false;
      }
      this.tableData = res.data.data.list;
      console.log(this.companyList);
      for (let i = 0; i < this.tableData.length; i++) {
        for (let j = 0; j < this.companyList.length; j++) {
          if (this.tableData[i].ciId == this.companyList[j].id) {
            this.tableData[i].ciId = this.companyList[j].name;
          }
        }
      }
      this.total = res.data.data.total;
      console.log(this.tableData);
    },

    // 选公司
    checkedCompany(val) {
      if (this.$refs.cascader.getCheckedNodes()[0] == undefined) {
        this.ciId = "";
      } else {
        this.ciId = this.$refs.cascader.getCheckedNodes()[0].data.id;
      }
      this.$refs.cascader.dropDownVisible = false;
    },

    // 选时间
    datePicker(value) {
      if (value) {
        // 判断选择的endtime是否为今天
        if (this.pickerValue[1].slice(0,10)===this.$moment().format("YYYY-MM-DD"))
        {
          console.log(this.pickerValue[1].slice(0,10));
          console.log(this.$moment().format("YYYY-MM-DD"));
          this.pickerValue[0] = this.$moment(value[0]).format("YYYY-MM-DD HH:mm:ss");
          // 选择的日期减一
          this.pickerValue[1] = this.$moment(value[1]).subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss");
        } else { 
          // 不变
          this.pickerValue[0] = this.$moment(value[0]).format("YYYY-MM-DD HH:mm:ss");
          this.pickerValue[1] = this.$moment(value[1]).format("YYYY-MM-DD HH:mm:ss");
        }
      }
      console.log(this.pickerValue[1].slice(0,10));
      console.log(this.$moment().format("YYYY-MM-DD"));
      console.log(this.pickerValue[1]);
    },

    // pagination(val) {
    //   this.current = val;
    //   this.getDeviceData();
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/tableCss.scss";
@font-face {
  font-family: "led regular";
  src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
}

.container {
  position: relative;
  box-sizing: border-box;
  padding: 90px 10px 0 10px;
  height: 100%;

  .content {
    height: 90%;
    padding: 10px 0;
  }
  .summation {
    position: relative;
    position: absolute;
    bottom: 0px;
    padding-left: 15px;
    font-size: 20px;
    height: 40px;
    width: 98%;
    line-height: 40px;
    margin: 0;
    background-color: rgba($color: #000a25, $alpha: 0.85);
    color: aqua;
    span {
      font-size: 36px;
      font-family: "led regular";
      position: absolute;
      right: 20px;
    }
  }
}
/deep/.el-table__body tr:hover>td {
background-color:rgba($color: #007a91, $alpha: 0.5) !important;
color: #72d4dc;
}
</style>